import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="zipcloud"
export default class extends Controller {
  static targets = [ "postcode", "address", "zipcloudAddress" ]

  search() {
    const postcode = this.postcodeTarget.value
    if (postcode.length !== 7) {
        alert('郵便番号は7桁で入力してください');
        return;
    }
    fetch(`/zipclouds/${postcode}`)
        .then(response => response.json())
        .then(data => {
          if (data.error?.length > 0) {
              alert(data.error);
          } else {
              this.addressTarget.value = data.address;
              if (this.hasZipcloudAddressTarget) this.zipcloudAddressTarget.value = data.address;
          }
        });
  }
}
