import { Controller } from "@hotwired/stimulus"
import debounce from 'lodash.debounce';

// Connects to data-controller="insurer-name"
export default class extends Controller {
  static targets = [ "input", "list", "listFrame" ]
  connect() {
    this.search = debounce(this.search.bind(this), 500);
    this.listTarget.hidden = true;
    // 要素より外側がクリックされたら非表示にする
    document.addEventListener('click', this.handleOutsideClick);
  }

  disconnect() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  search() {
    const name = this.inputTarget.value;
    this.listFrameTarget.src = `/insurer_names?name=${name}&form_suggest=1`;
    this.listTarget.hidden = false;
  }

  select(event) {
    this.inputTarget.value = event.currentTarget.dataset.value;
    this.listTarget.hidden = true;
  }

  handleOutsideClick = (event) => {
    if (!this.element.contains(event.target)) {
      this.listTarget.hidden = true;
    }
  }
}
