import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="book"
export default class extends Controller {
  static targets = [ "deliverAddress", "organizationCode", "officeArea", "insurerNumber" ]

  connect() {
    document.addEventListener('turbo:submit-end', (event) => {
      if (!event.detail.success) {
        window.scrollTo(0, 0 );
      }
    })
  }

  changeSameAddress(event) {
    this.deliverAddressTarget.style.display = event.target.checked ? 'none' : 'block'
  }

  changeInsuranceKind(event) {
    if (event.target.value === 'owner' || (this.organizationCodeTarget.value === 'other' && event.target.value === 'family')) {
      this.officeAreaTarget.style.display = 'block'
    } else {
      this.officeAreaTarget.style.display = 'none'
    }
  }

  selectKyoukaiInsurerName(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    this.insurerNumberTarget.value = selectedOption.dataset.number
  }
}
