import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="course"
export default class extends Controller {
  static targets = [ "courseCard", "courseId", "insuranceCard", "insuranceId", "ageMessage" ]

  connect() {
  }

  setCourseActive(event) {
    this.courseCardTargets.forEach(function(e) {
      e.classList.remove('active')
    })
    event.currentTarget.classList.add('active')
    this.courseIdTargets.forEach(function(elm) {
      elm.checked = elm.value === event.currentTarget.dataset.id
    })

    const message = event.currentTarget.dataset.ageMessage
    if (message && message.length > 0) {
      this.ageMessageTarget.innerHTML = '<div class="alert alert-info mb-4">' + event.currentTarget.dataset.ageMessage + '</div>'
      this.ageMessageTarget.hidden = false
    } else {
      this.ageMessageTarget.innerHTML = ''
      this.ageMessageTarget.hidden = true
    }
  }

  setInsuranceActive(event) {
    this.insuranceCardTargets.forEach(function(e) {
      e.classList.remove('active')
    })
    event.currentTarget.classList.add('active')
    this.insuranceIdTargets.forEach(function(elm) {
      elm.checked = elm.value === event.currentTarget.dataset.id
    })
  }
}
